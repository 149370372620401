import BigNumber from 'bignumber.js'
import ERC20ABI from 'config/abi/erc20.json'
import multicall from 'utils/multicall'
import { getCakeAddress, getOldSatAddress, getMasterChefAddress } from 'utils/addressHelpers'

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID

const fetchSatBalance = async (account: string) => {
  const masterChefAdress = getMasterChefAddress()
  const calls = [
    {
      address: getCakeAddress(),
      name: 'balanceOf',
      params: [account],
    },
    {
      address: getOldSatAddress(),
      name: 'balanceOf',
      params: [account],
    },
    {
      address: getOldSatAddress(),
      name: 'allowance',
      params: [account, masterChefAdress],
    },
  ]

  const [newSatBalance, oldSatBalance, oldSatAllowance] = await multicall(ERC20ABI, calls)
  return {
    newSatBalance: new BigNumber(newSatBalance),
    oldSatBalance: new BigNumber(oldSatBalance),
    oldSatAllowance: new BigNumber(oldSatAllowance),
  }
}

export default fetchSatBalance
