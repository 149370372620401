import React, { useEffect, Suspense, lazy, useState } from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import { ResetCSS } from '@pancakeswap-libs/uikit'
import BigNumber from 'bignumber.js'
import { useFetchPublicData, useFetchVaultPublicData } from 'state/hooks'
import GlobalStyle from './style/Global'
import Menu from './components/Menu'
import PageLoader from './components/PageLoader'
import NftGlobalNotification from './views/Nft/components/NftGlobalNotification'
import PreOpen from './components/PreOpen'

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page'
const Home = lazy(() => import('./views/Home'))
const Farms = lazy(() => import('./views/Farms'))
const Vaults = lazy(() => import('./views/Vaults'))
const Buyback = lazy(() => import('./views/Buyback'))
const Referrals = lazy(() => import('./views/Referrals'))
const MigrationSat = lazy(() => import('./views/MigrationSat'))
const Lottery = lazy(() => import('./views/Lottery'))
// const Pools = lazy(() => import('./views/Pools'))
// const Ifos = lazy(() => import('./views/Ifos'))
const NotFound = lazy(() => import('./views/NotFound'))
const Nft = lazy(() => import('./views/Nft'))

// This config is required for number formating
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const App: React.FC = () => {
  const { account, connect } = useWallet()
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const ref = urlParams.get('ref') ? urlParams.get('ref') : '0x0000000000000000000000000000000000000000'
  const [referral, setReferral] = useState('0x0000000000000000000000000000000000000000')
  if (
    (referral === '' || referral === '0x0000000000000000000000000000000000000000') &&
    ref !== '0x0000000000000000000000000000000000000000'
  ) {
    setReferral(ref)
  }
  useEffect(() => {
    if (!account && window.localStorage.getItem('accountStatus')) {
      connect('injected')
    }
  }, [account, connect])

  useFetchPublicData()
  useFetchVaultPublicData()

  if (new Date().getTime() < 1608547400000) {
    return <PreOpen unixEndDate={1618547400000} />
  }

  return (
    <Router>
      <ResetCSS />
      <GlobalStyle />
      <Menu>
        <Suspense fallback={<PageLoader />}>
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="/farms">
              <Farms referral={referral} />
            </Route>
            <Route path="/farmsLegacy">
              <Farms isLegacy referral={referral} />
            </Route>
            <Route path="/pools">
              <Farms tokenMode referral={referral} />
            </Route>
            <Route path="/vaults">
              <Vaults />
            </Route>
            <Route path="/buyback">
              <Buyback />
            </Route>
            <Route path="/referrals">
              <Referrals />
            </Route>
            <Route path="/migrationSat">
              <MigrationSat />
            </Route>
            <Route path="/lottery">
              <Lottery />
            </Route>

            {/* <Route path="/ifo"> */}
            {/*  <Ifos /> */}
            {/* </Route> */}
            <Route path="/nft">
              <Nft />
            </Route>
            {/* Redirect */}
            {/* <Route path="/staking"> */}
            {/*  <Redirect to="/pools" /> */}
            {/* </Route> */}
            {/* <Route path="/syrup"> */}
            {/*  <Redirect to="/pools" /> */}
            {/* </Route> */}
            {/* 404 */}
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </Menu>
      <NftGlobalNotification />
    </Router>
  )
}

export default React.memo(App)
