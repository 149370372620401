import React from 'react'
import moment from 'moment'
import { Text } from '@pancakeswap-libs/uikit'
import styled from 'styled-components'
import { useCurrentTime } from 'hooks/useTimer'
import { SocialIcon } from 'react-social-icons'

const Main = styled.div`
  background: rgb(255, 255, 255);
  width: 100%;
  height: 100%;
  padding: 60px;
`

const Title = styled.div`
  color: rgb(66, 171, 255);
  font-size: 78px;
  font-weight: bold;
`

const Container = styled.div`
  color: rgb(66, 171, 255);
  font-family: 'Noto Sans';
  margin: 0px 0px 0px 0px;
  width: 100%;
  text-align: center;
`

const Card = styled.div`
  display: inline-block;
  margin: 10px;
  min-width: 100px;
`
const Value = styled.div`
  font-size: 2rem;
  margin-bottom: 10px;
`
const Unit = styled.div`
  text-transform: capitalize;
`
const DocsLink = styled.a`
  color: inherit;
  //text-decoration: inherit;
`

const Countdown = ({ unixEndDate }) => {
  const endDate = new Date(unixEndDate)
  const distance: number = Math.floor((unixEndDate - useCurrentTime()) / 1000)
  const days: number = Math.floor(distance / (60 * 60 * 24))
  const hours: number = Math.floor((distance % (60 * 60 * 24)) / (60 * 60))
  const mins: number = Math.floor((distance % (60 * 60)) / 60)
  const secs: number = Math.floor(distance % 60)

  return (
    <Main>
      <Container>
        <Title>SatisFinance</Title>
        <p style={{ margin: 0 }}>SatisFinance is 4th generation deflationary yield farming project</p>
      </Container>
      <Container>
        <Card>
          <Value>{days}</Value>
          <Unit>Days</Unit>
        </Card>
        <Card>
          <Value>{hours}</Value>
          <Unit>Hours</Unit>
        </Card>
        <Card>
          <Value>{mins}</Value>
          <Unit>Mins</Unit>
        </Card>
        <Card>
          <Value>{secs}</Value>
          <Unit>Secs</Unit>
        </Card>
        <p style={{ marginTop: 40 }}>Counting down to {endDate.toString()}</p>
        <p>
          If you want to get more details...read <DocsLink href="https://docs.satis.finance/">docs</DocsLink>
        </p>
      </Container>
      <Container>
        <a href="https://twitter.com/FinanceSatis" style={{ margin: 4 }}>
          <SocialIcon network="twitter" bgColor="#42ABFF" style={{ height: 30, width: 30 }} />
        </a>
        <a href="https://medium.com/@SatisFinance" style={{ margin: 4 }}>
          <SocialIcon network="medium" bgColor="#42ABFF" style={{ height: 30, width: 30 }} />
        </a>
        <a href="https://github.com/RenovJ/SatisFi-contracts" style={{ margin: 4 }}>
          <SocialIcon network="github" bgColor="#42ABFF" style={{ height: 30, width: 30 }} />
        </a>
        <a href="https://t.me/satisfiChat" style={{ margin: 4 }}>
          <SocialIcon network="telegram" bgColor="#42ABFF" style={{ height: 30, width: 30 }} />
        </a>
      </Container>
    </Main>
  )
}

export default Countdown
