import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 10,
    risk: 5,
    lpSymbol: 'xSAT-BUSD',
    lpAddresses: {
      97: '',
      56: '0x3357387b7341c341ffac18ada7cf2c242bc82b84',
    },
    tokenSymbol: 'SAT',
    tokenAddresses: {
      97: '',
      56: '0x8fda94079913CB921D065Ed9c004Afb43e1f900e',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 11,
    risk: 5,
    lpSymbol: 'xSAT-BNB',
    lpAddresses: {
      97: '',
      56: '0x15a24f7850bce4818617c1efa2d24132e65809dc',
    },
    tokenSymbol: 'SAT',
    tokenAddresses: {
      97: '',
      56: '0x8fda94079913CB921D065Ed9c004Afb43e1f900e',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 12,
    risk: 5,
    lpSymbol: 'xSAT-CAKE',
    lpAddresses: {
      97: '',
      56: '0x372B052Ac91151C791ABa5274fEF6DF5968dDEa0',
    },
    tokenSymbol: 'CAKE',
    tokenAddresses: {
      97: '',
      56: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    },
    quoteTokenSymbol: QuoteToken.SAT,
    quoteTokenAdresses: contracts.cake,
  },
  {
    pid: 15,
    risk: 5,
    lpSymbol: 'xSAT-USDT LP',
    lpAddresses: {
      97: '',
      56: '0x2C48c7b895c088a95384adB836011660F59622cC',
    },
    tokenSymbol: 'USDT',
    tokenAddresses: {
      97: '',
      56: '0x55d398326f99059fF775485246999027B3197955',
    },
    quoteTokenSymbol: QuoteToken.SAT,
    quoteTokenAdresses: contracts.cake,
  },
  {
    pid: 13,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'xSAT',
    lpAddresses: {
      97: '',
      56: '0x3357387b7341c341ffac18ada7cf2c242bc82b84',
    },
    tokenSymbol: 'SAT',
    tokenAddresses: {
      97: '',
      56: '0x8fda94079913CB921D065Ed9c004Afb43e1f900e',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 0,
    risk: 1,
    lpSymbol: 'BNB-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x58f876857a02d6762e0101bb5c46a8c1ed44dc16',
    },
    tokenSymbol: 'BNB',
    tokenAddresses: {
      97: '',
      56: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 1,
    risk: 1,
    lpSymbol: 'USDT-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x7efaef62fddcca950418312c6c91aef321375a00',
    },
    tokenSymbol: 'USDT',
    tokenAddresses: {
      97: '',
      56: '0x55d398326f99059fF775485246999027B3197955',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 2,
    risk: 1,
    lpSymbol: 'BTCB-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x61eb789d75a95caa3ff50ed7e47b96c132fec082',
    },
    tokenSymbol: 'BTCB',
    tokenAddresses: {
      97: '',
      56: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 3,
    risk: 1,
    lpSymbol: 'BELT-BNB LP',
    lpAddresses: {
      97: '',
      56: '0xf3bc6fc080ffcc30d93df48bfa2aa14b869554bb',
    },
    tokenSymbol: 'BELT',
    tokenAddresses: {
      97: '',
      56: '0xe0e514c71282b6f4e823703a39374cf58dc3ea4f',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 4,
    risk: 1,
    lpSymbol: 'CAKE-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x0ed7e52944161450477ee417de9cd3a859b14fd0',
    },
    tokenSymbol: 'CAKE',
    tokenAddresses: {
      97: '',
      56: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 5,
    risk: 1,
    lpSymbol: 'LINA-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0xc5768c5371568cf1114cddd52caed163a42626ed',
    },
    tokenSymbol: 'LINA',
    tokenAddresses: {
      97: '',
      56: '0x762539b45A1dCcE3D36d080F74d1AED37844b878',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 6,
    risk: 1,
    lpSymbol: 'BRY-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x21dd71ab78ede3033c976948f769d506e4f489ee',
    },
    tokenSymbol: 'BRY',
    tokenAddresses: {
      97: '',
      56: '0xf859Bf77cBe8699013d6Dbc7C2b926Aaf307F830',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 7,
    risk: 1,
    lpSymbol: 'TRX-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x3cd338c3BB249B6b3C55799F85a589FEbBBFf9Dd',
    },
    tokenSymbol: 'TRX',
    tokenAddresses: {
      97: '',
      56: '0x85EAC5Ac2F758618dFa09bDbe0cf174e7d574D5B',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 16,
    risk: 1,
    lpSymbol: 'XRP-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x03F18135c44C64ebFdCBad8297fe5bDafdBbdd86',
    },
    tokenSymbol: 'XRP',
    tokenAddresses: {
      97: '',
      56: '0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 17,
    risk: 1,
    lpSymbol: 'ADA-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x28415ff2C35b65B9E5c7de82126b4015ab9d031F',
    },
    tokenSymbol: 'ADA',
    tokenAddresses: {
      97: '',
      56: '0x3ee2200efb3400fabb9aacf31297cbdd1d435d47',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 9,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'vBSWAP',
    lpAddresses: {
      97: '',
      56: '0x8dd39f0a49160cda5ef1e2a2fa7396eec7da8267',
    },
    tokenSymbol: 'vBSWAP',
    tokenAddresses: {
      97: '',
      56: '0x4f0ed527e8A95ecAA132Af214dFd41F30b361600',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 8,
    risk: 1,
    lpSymbol: 'vBSWAP-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x8dd39f0a49160cda5ef1e2a2fa7396eec7da8267',
    },
    tokenSymbol: 'vBSWAP',
    tokenAddresses: {
      97: '',
      56: '0x4f0ed527e8A95ecAA132Af214dFd41F30b361600',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 14,
    risk: 1,
    lpSymbol: 'xSAT-GARUDA LP',
    lpAddresses: {
      97: '',
      56: '0x6f88b15beeda3d4db3133ba56e5deedb8c272629',
    },
    tokenSymbol: 'GARUDA',
    tokenAddresses: {
      97: '',
      56: '0x854086dC841e1bfae50Cb615bF41f55BF432a90b',
    },
    quoteTokenSymbol: QuoteToken.SAT,
    quoteTokenAdresses: contracts.cake,
  },
  /* for YetiMaster Testing
  // LP V2
  {
    pid: 32,
    risk: 5,
    lpSymbol: 'SAT-BUSD V2 LP',
    lpAddresses: {
      97: '',
      56: '0x020db9ff8648acfc9c590eafb8f6bcd8f254d248',
    },
    tokenSymbol: 'SAT',
    tokenAddresses: {
      97: '',
      56: '0xA1928c0D8F83C0bFB7ebE51B412b1FD29A277893',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 33,
    risk: 5,
    lpSymbol: 'SAT-BNB V2 LP',
    lpAddresses: {
      97: '',
      56: '0x0ab22917fa941edca92a5b7e030c59fbc7d9019a',
    },
    tokenSymbol: 'SAT',
    tokenAddresses: {
      97: '',
      56: '0xA1928c0D8F83C0bFB7ebE51B412b1FD29A277893',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 43,
    risk: 5,
    lpSymbol: 'SAT-CAKE V2 LP',
    lpAddresses: {
      97: '',
      56: '0xfe5f79ca1b7892dbb4e5f2d55c6007e809c0730a',
    },
    tokenSymbol: 'CAKE',
    tokenAddresses: {
      97: '',
      56: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82', // cake
      //       56: '0xA1928c0D8F83C0bFB7ebE51B412b1FD29A277893', // sat
    },
    quoteTokenSymbol: QuoteToken.SAT,
    quoteTokenAdresses: contracts.cake, // sat
  },

  {
    pid: 34,
    risk: 1,
    lpSymbol: 'BNB-BUSD V2 LP',
    lpAddresses: {
      97: '',
      56: '0x58f876857a02d6762e0101bb5c46a8c1ed44dc16',
    },
    tokenSymbol: 'BNB',
    tokenAddresses: {
      97: '',
      56: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 35,
    risk: 1,
    lpSymbol: 'USDT-BUSD V2 LP',
    lpAddresses: {
      97: '',
      56: '0x7efaef62fddcca950418312c6c91aef321375a00',
    },
    tokenSymbol: 'USDT',
    tokenAddresses: {
      97: '',
      56: '0x55d398326f99059fF775485246999027B3197955',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 37,
    risk: 1,
    lpSymbol: 'BTCB-BNB V2 LP',
    lpAddresses: {
      97: '',
      56: '0x61eb789d75a95caa3ff50ed7e47b96c132fec082',
    },
    tokenSymbol: 'BTCB',
    tokenAddresses: {
      97: '',
      56: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 40,
    risk: 3,
    lpSymbol: 'BELT-BNB V2 LP',
    lpAddresses: {
      97: '',
      56: '0xf3bc6fc080ffcc30d93df48bfa2aa14b869554bb',
    },
    tokenSymbol: 'BELT',
    tokenAddresses: {
      97: '',
      56: '0xe0e514c71282b6f4e823703a39374cf58dc3ea4f',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 42,
    risk: 3,
    lpSymbol: 'CAKE-BNB V2 LP',
    lpAddresses: {
      97: '',
      56: '0x0ed7e52944161450477ee417de9cd3a859b14fd0',
    },
    tokenSymbol: 'CAKE',
    tokenAddresses: {
      97: '',
      56: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 30,
    risk: 3,
    lpSymbol: 'vBSWAP-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x8DD39f0a49160cDa5ef1E2a2fA7396EEc7DA8267',
    },
    tokenSymbol: 'vBSWAP',
    tokenAddresses: {
      97: '',
      56: '0x4f0ed527e8A95ecAA132Af214dFd41F30b361600',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  // SINGLE POOL
  {
    pid: 25,
    risk: 5,
    lpSymbol: 'SAT',
    isTokenOnly: true,
    lpAddresses: {
      97: '',
      56: '0xfa4202d6d4f1f39194457c1e55834721c27c3430',
    },
    tokenSymbol: 'SAT',
    tokenAddresses: {
      97: '',
      56: '0xA1928c0D8F83C0bFB7ebE51B412b1FD29A277893',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 11,
    risk: 1,
    lpSymbol: 'WBNB',
    isTokenOnly: true,
    lpAddresses: {
      97: '',
      56: '0x1b96b92314c44b159149f7e0303511fb2fc4774f',
    },
    tokenSymbol: 'WBNB',
    tokenAddresses: {
      97: '',
      56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 13,
    risk: 1,
    lpSymbol: 'BTCB',
    isTokenOnly: true,
    lpAddresses: {
      97: '',
      56: '0x7561eee90e24f3b348e1087a005f78b4c8453524',
    },
    tokenSymbol: 'BTCB',
    tokenAddresses: {
      97: '',
      56: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 18,
    risk: 3,
    lpSymbol: 'CAKE',
    isTokenOnly: true,
    lpAddresses: {
      97: '',
      56: '0xa527a61703d82139f8a06bc30097cc9caa2df5a6',
    },
    tokenSymbol: 'CAKE',
    tokenAddresses: {
      97: '',
      56: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 31,
    risk: 3,
    lpSymbol: 'vBSWAP',
    isTokenOnly: true,
    lpAddresses: {
      97: '',
      56: '0x8DD39f0a49160cDa5ef1E2a2fA7396EEc7DA8267',
    },
    tokenSymbol: 'vBSWAP',
    tokenAddresses: {
      97: '',
      56: '0x4f0ed527e8A95ecAA132Af214dFd41F30b361600',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
   {
     pid: 23,
     risk: 1,
     lpSymbol: 'SAT-BUSD LP',
     lpAddresses: {
       97: '',
       56: '0xfa4202d6d4f1f39194457c1e55834721c27c3430',
     },
     tokenSymbol: 'SAT',
     tokenAddresses: {
       97: '',
       56: '0xA1928c0D8F83C0bFB7ebE51B412b1FD29A277893',
     },
     quoteTokenSymbol: QuoteToken.BUSD,
     quoteTokenAdresses: contracts.busd,
     isLegacy: true,
   },
   {
     pid: 24,
     risk: 1,
     lpSymbol: 'SAT-BNB LP',
     lpAddresses: {
       97: '',
       56: '0xc85275a709d6e6a6999b49eb419608599979fca5',
     },
     tokenSymbol: 'SAT',
     tokenAddresses: {
       97: '',
       56: '0xA1928c0D8F83C0bFB7ebE51B412b1FD29A277893',
     },
     quoteTokenSymbol: QuoteToken.BNB,
     quoteTokenAdresses: contracts.wbnb,
     isLegacy: true,
   },
   {
     pid: 0,
     risk: 1,
     lpSymbol: 'BNB-BUSD LP',
     lpAddresses: {
       97: '',
       56: '0x1b96b92314c44b159149f7e0303511fb2fc4774f',
     },
     tokenSymbol: 'BNB',
     tokenAddresses: {
       97: '',
       56: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
     },
     quoteTokenSymbol: QuoteToken.BUSD,
     quoteTokenAdresses: contracts.busd,
     isLegacy: true,
   },
   {
     pid: 1,
     risk: 1,
     lpSymbol: 'USDT-BUSD LP',
     lpAddresses: {
       97: '',
       56: '0xc15fa3e22c912a276550f3e5fe3b0deb87b55acd',
     },
     tokenSymbol: 'USDT',
     tokenAddresses: {
       97: '',
       56: '0x55d398326f99059fF775485246999027B3197955',
     },
     quoteTokenSymbol: QuoteToken.BUSD,
     quoteTokenAdresses: contracts.busd,
     isLegacy: true,
   },
   {
     pid: 2,
     risk: 1,
     lpSymbol: 'USDT-BNB LP',
     lpAddresses: {
       97: '',
       56: '0x20bcc3b8a0091ddac2d0bc30f68e6cbb97de59cd',
     },
     tokenSymbol: 'USDT',
     tokenAddresses: {
       97: '',
       56: '0x55d398326f99059fF775485246999027B3197955',
     },
     quoteTokenSymbol: QuoteToken.BNB,
     quoteTokenAdresses: contracts.wbnb,
     isLegacy: true,
   },
   {
     pid: 3,
     risk: 1,
     lpSymbol: 'BTCB-BNB LP',
     lpAddresses: {
       97: '',
       56: '0x7561eee90e24f3b348e1087a005f78b4c8453524',
     },
     tokenSymbol: 'BTCB',
     tokenAddresses: {
       97: '',
       56: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
     },
     quoteTokenSymbol: QuoteToken.BNB,
     quoteTokenAdresses: contracts.wbnb,
     isLegacy: true,
   },
   {
     pid: 4,
     risk: 1,
     lpSymbol: 'ETH-BNB LP',
     lpAddresses: {
       97: '',
       56: '0x70d8929d04b60af4fb9b58713ebcf18765ade422',
     },
     tokenSymbol: 'ETH',
     tokenAddresses: {
       97: '',
       56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
     },
     quoteTokenSymbol: QuoteToken.BNB,
     quoteTokenAdresses: contracts.wbnb,
     isLegacy: true,
   },
   {
     pid: 5,
     risk: 1,
     lpSymbol: 'DAI-BUSD LP',
     lpAddresses: {
       97: '',
       56: '0x3ab77e40340ab084c3e23be8e5a6f7afed9d41dc',
     },
     tokenSymbol: 'DAI',
     tokenAddresses: {
       97: '',
       56: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
     },
     quoteTokenSymbol: QuoteToken.BUSD,
     quoteTokenAdresses: contracts.busd,
     isLegacy: true,
   },
   {
     pid: 6,
     risk: 3,
     lpSymbol: 'BELT-BNB LP',
     lpAddresses: {
       97: '',
       56: '0x83B92D283cd279fF2e057BD86a95BdEfffED6faa',
     },
     tokenSymbol: 'BELT',
     tokenAddresses: {
       97: '',
       56: '0xe0e514c71282b6f4e823703a39374cf58dc3ea4f',
     },
     quoteTokenSymbol: QuoteToken.BNB,
     quoteTokenAdresses: contracts.wbnb,
     isLegacy: true,
   },
   {
     pid: 8,
     risk: 3,
     lpSymbol: 'ITAM-BNB LP',
     lpAddresses: {
       97: '',
       56: '0xcdc53345192d0e31eead03d7e9e008ee659faebe',
     },
     tokenSymbol: 'ITAM',
     tokenAddresses: {
       97: '',
       56: '0x04C747b40Be4D535fC83D09939fb0f626F32800B',
     },
     quoteTokenSymbol: QuoteToken.BNB,
     quoteTokenAdresses: contracts.wbnb,
     isLegacy: true,
   },
   {
     pid: 9,
     risk: 3,
     lpSymbol: 'CAKE-BNB LP',
     lpAddresses: {
       97: '',
       56: '0xa527a61703d82139f8a06bc30097cc9caa2df5a6',
     },
     tokenSymbol: 'CAKE',
     tokenAddresses: {
       97: '',
       56: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
     },
     quoteTokenSymbol: QuoteToken.BNB,
     quoteTokenAdresses: contracts.wbnb,
     isLegacy: true,
   },
                             
   // 2021-04-29 removed
   {
     pid: 36,
     risk: 1,
     lpSymbol: 'USDT-BNB V2 LP',
     lpAddresses: {
       97: '',
       56: '0x16b9a82891338f9ba80e2d6970fdda79d1eb0dae',
     },
     tokenSymbol: 'USDT',
     tokenAddresses: {
       97: '',
       56: '0x55d398326f99059fF775485246999027B3197955',
     },
     quoteTokenSymbol: QuoteToken.BNB,
     quoteTokenAdresses: contracts.wbnb,
   },
   {
     pid: 38,
     risk: 1,
     lpSymbol: 'ETH-BNB V2 LP',
     lpAddresses: {
       97: '',
       56: '0x74e4716e431f45807dcf19f284c7aa99f18a4fbc',
     },
     tokenSymbol: 'ETH',
     tokenAddresses: {
       97: '',
       56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
     },
     quoteTokenSymbol: QuoteToken.BNB,
     quoteTokenAdresses: contracts.wbnb,
   },
   {
     pid: 39,
     risk: 1,
     lpSymbol: 'DAI-BUSD V2 LP',
     lpAddresses: {
       97: '',
       56: '0x66fdb2eccfb58cf098eaa419e5efde841368e489',
     },
     tokenSymbol: 'DAI',
     tokenAddresses: {
       97: '',
       56: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
     },
     quoteTokenSymbol: QuoteToken.BUSD,
     quoteTokenAdresses: contracts.busd,
   },
   {
     pid: 41,
     risk: 3,
     lpSymbol: 'ITAM-BNB V2 LP',
     lpAddresses: {
       97: '',
       56: '0xd02da76c813b9cd4516ed50442923e625f90228f',
     },
     tokenSymbol: 'ITAM',
     tokenAddresses: {
       97: '',
       56: '0x04C747b40Be4D535fC83D09939fb0f626F32800B',
     },
     quoteTokenSymbol: QuoteToken.BNB,
     quoteTokenAdresses: contracts.wbnb,
   },
   {
     pid: 26,
     risk: 3,
     lpSymbol: 'FINIX-BNB LP',
     lpAddresses: {
       97: '',
       56: '0xb2a2048de5afb312fcf076abf505952c12916f0d',
     },
     tokenSymbol: 'FINIX',
     tokenAddresses: {
       97: '',
       56: '0x0f02b1f5af54e04fb6dd6550f009ac2429c4e30d',
     },
     quoteTokenSymbol: QuoteToken.BNB,
     quoteTokenAdresses: contracts.wbnb,
   },
   {
     pid: 10,
     risk: 1,
     lpSymbol: 'BUSD',
     isTokenOnly: true,
     lpAddresses: {
       97: '',
       56: '0x1b96b92314c44b159149f7e0303511fb2fc4774f',
     },
     tokenSymbol: 'BUSD',
     tokenAddresses: {
       97: '',
       56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
     },
     quoteTokenSymbol: QuoteToken.BUSD,
     quoteTokenAdresses: contracts.busd,
   },
   {
     pid: 12,
     risk: 1,
     lpSymbol: 'USDT',
     isTokenOnly: true,
     lpAddresses: {
       97: '',
       56: '0xc15fa3e22c912a276550f3e5fe3b0deb87b55acd',
     },
     tokenSymbol: 'USDT',
     tokenAddresses: {
       97: '',
       56: '0x55d398326f99059fF775485246999027B3197955',
     },
     quoteTokenSymbol: QuoteToken.BUSD,
     quoteTokenAdresses: contracts.busd,
   },
   {
     pid: 14,
     risk: 1,
     lpSymbol: 'ETH',
     isTokenOnly: true,
     lpAddresses: {
       97: '',
       56: '0x70d8929d04b60af4fb9b58713ebcf18765ade422',
     },
     tokenSymbol: 'ETH',
     tokenAddresses: {
       97: '',
       56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
     },
     quoteTokenSymbol: QuoteToken.BNB,
     quoteTokenAdresses: contracts.wbnb,
   },
   {
     pid: 15,
     risk: 1,
     lpSymbol: 'DAI',
     isTokenOnly: true,
     lpAddresses: {
       97: '',
       56: '0x3ab77e40340ab084c3e23be8e5a6f7afed9d41dc',
     },
     tokenSymbol: 'DAI',
     tokenAddresses: {
       97: '',
       56: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
     },
     quoteTokenSymbol: QuoteToken.BUSD,
     quoteTokenAdresses: contracts.busd,
   },
   {
     pid: 16,
     risk: 1,
     lpSymbol: 'USDC',
     isTokenOnly: true,
     lpAddresses: {
       97: '',
       56: '0x680dd100e4b394bda26a59dd5c119a391e747d18',
     },
     tokenSymbol: 'USDC',
     tokenAddresses: {
       97: '',
       56: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
     },
     quoteTokenSymbol: QuoteToken.BUSD,
     quoteTokenAdresses: contracts.busd,
   },
   {
     pid: 17,
     risk: 3,
     lpSymbol: 'BELT',
     isTokenOnly: true,
     lpAddresses: {
       97: '',
       56: '0x83B92D283cd279fF2e057BD86a95BdEfffED6faa',
     },
     tokenSymbol: 'BELT',
     tokenAddresses: {
       97: '',
       56: '0xe0e514c71282b6f4e823703a39374cf58dc3ea4f',
     },
     quoteTokenSymbol: QuoteToken.BNB,
     quoteTokenAdresses: contracts.wbnb,
   },
   {
     pid: 19,
     risk: 3,
     lpSymbol: 'FINIX',
     isTokenOnly: true,
     lpAddresses: {
       97: '',
       56: '0xb2a2048de5afb312fcf076abf505952c12916f0d',
     },
     tokenSymbol: 'FINIX',
     tokenAddresses: {
       97: '',
       56: '0x0f02b1f5af54e04fb6dd6550f009ac2429c4e30d',
     },
     quoteTokenSymbol: QuoteToken.BNB,
     quoteTokenAdresses: contracts.wbnb,
   },
   {
     pid: 20,
     risk: 3,
     lpSymbol: 'AUTO',
     isTokenOnly: true,
     lpAddresses: {
       97: '',
       56: '0x4d0228ebeb39f6d2f29ba528e2d15fc9121ead56',
     },
     tokenSymbol: 'AUTO',
     tokenAddresses: {
       97: '',
       56: '0xa184088a740c695e156f91f5cc086a06bb78b827',
     },
     quoteTokenSymbol: QuoteToken.BNB,
     quoteTokenAdresses: contracts.wbnb,
   },
   {
     pid: 21,
     risk: 3,
     lpSymbol: 'ITAM',
     isTokenOnly: true,
     lpAddresses: {
       97: '',
       56: '0xcdc53345192d0e31eead03d7e9e008ee659faebe',
     },
     tokenSymbol: 'ITAM',
     tokenAddresses: {
       97: '',
       56: '0x04C747b40Be4D535fC83D09939fb0f626F32800B',
     },
     quoteTokenSymbol: QuoteToken.BNB,
     quoteTokenAdresses: contracts.wbnb,
   },
   {
     pid: 22,
     risk: 3,
     lpSymbol: 'EGG',
     isTokenOnly: true,
     lpAddresses: {
       97: '',
       56: '0xd1b59d11316e87c3a0a069e80f590ba35cd8d8d3',
     },
     tokenSymbol: 'EGG',
     tokenAddresses: {
       97: '',
       56: '0xf952fc3ca7325cc27d15885d37117676d25bfda6',
     },
     quoteTokenSymbol: QuoteToken.BNB,
     quoteTokenAdresses: contracts.wbnb,
   },
 */
]

export default farms
