export default {
  oldSat: {
    56: '0xA1928c0D8F83C0bFB7ebE51B412b1FD29A277893',
    97: '0x3e5Def4BC1c067DD4187D4e3D1919e868b7fC8f4',
  },
  cake: {
    56: '0x8fda94079913CB921D065Ed9c004Afb43e1f900e', // xSAT
    97: '0x3e5Def4BC1c067DD4187D4e3D1919e868b7fC8f4',
  },
  wcake: {
    56: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    97: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
  },
  masterChef: {
    //    56: '0x9b1dfEF15251AAF3540C1d008d4c4Aa6f636339d',
    56: '0x2EaB54f9f57057B0C213579c104e6f1834e38B26', // yetimaster
    97: '0x0e851327A6d1d49def350e0FbEb897E5f8368015',
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '0x0421b6ce68c71708cd18652af5123fc2573dbccc',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '0xed24fc36d5ee211ea25a80239fb8c4cfd80f12ee',
  },
  buyback: {
    56: '0x355Da6b2b062316F279E85f9C5Eeb3373470F739',
    97: '0xAEA2ecFBB59d0815B9F8e9111DFf640f7F49BC50',
  },
  referral: {
    56: '0xa12E4C9fdD0d21346e1B1Bdc431759E9974e6ED6',
    97: '',
  },
  lottery: {
    56: '0xfC6498EB75A9e8c74EAe61EB5A51337eA5C07f8b',
    97: '',
  },
  lotteryNFT: {
    56: '0xce13883b7D70A941287974Ca664221f43A5cA07B',
    97: '',
  },
}
