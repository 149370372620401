/* eslint-disable no-param-reassign */
import BigNumber from 'bignumber.js'
import { createSlice } from '@reduxjs/toolkit'
import fetchSatBalance from './fetchSatBalance'
import { SatBalanceState, SatBalance } from '../types'

const initialState: SatBalanceState = {
  data: {
    satBalance: {
      oldSatBalance: new BigNumber(0),
      newSatBalance: new BigNumber(0),
      oldSatAllowance: new BigNumber(0),
    },
  },
}

export const satBalanceSlice = createSlice({
  name: 'SatBalance',
  initialState,
  reducers: {
    setSatBalanceData: (state, action) => {
      const satBalanceData = action.payload
      state.data.satBalance = satBalanceData
    },
  },
})

// Actions
export const { setSatBalanceData } = satBalanceSlice.actions

// Thunks
export const fetchSatBalanceDataAsync = (account: string) => async (dispatch) => {
  const satBalance = await fetchSatBalance(account)
  dispatch(setSatBalanceData(satBalance))
}

export default satBalanceSlice.reducer
