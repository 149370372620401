import BigNumber from 'bignumber.js'
import erc20ABI from 'config/abi/erc20.json'
import buybackABI from 'config/abi/buyback.json'
import multicall from 'utils/multicall'
import { getBuybackAddress, getCakeAddress } from 'utils/addressHelpers'

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID

export const fetchBuybackUserAccount = async (account: string) => {
  const calls = [
    {
      address: getBuybackAddress(),
      name: 'waitingAmountTokenToSell',
      params: [account],
    },
    {
      address: getBuybackAddress(),
      name: 'withdrawableTokenToSell',
      params: [account],
    },
    {
      address: getBuybackAddress(),
      name: 'userBuybackedAmount',
      params: [account],
    },
  ]

  const [waitingAmount, sellTokenBalance, buybackTokenBalance] = await multicall(buybackABI, calls)
  return {
    waitingAmount: new BigNumber(waitingAmount),
    sellTokenBalance: new BigNumber(sellTokenBalance),
    buybackTokenBalance: new BigNumber(buybackTokenBalance),
  }
}

export const fetchBuybackUserBalance = async (account: string) => {
  const calls = [
    {
      address: getCakeAddress(),
      name: 'balanceOf',
      params: [account],
    },
    {
      address: getCakeAddress(),
      name: 'allowance',
      params: [account, getBuybackAddress()],
    },
  ]

  const [tokenBalance, allowance] = await multicall(erc20ABI, calls)
  return {
    tokenBalance: new BigNumber(tokenBalance),
    allowance: new BigNumber(allowance),
  }
}
