import BigNumber from 'bignumber.js'
import erc20 from 'config/abi/erc20.json'
import buybackABI from 'config/abi/buyback.json'
import multicall from 'utils/multicall'
import { getBuybackAddress } from 'utils/addressHelpers'

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID

const fetchPool = async () => {
  const calls = [
    {
      address: getBuybackAddress(),
      name: 'buybackPrice',
    },
    {
      address: getBuybackAddress(),
      name: 'epochBuybackAmount',
    },
    {
      address: getBuybackAddress(),
      name: 'totalBuybackAmountToProvide',
    },
    {
      address: getBuybackAddress(),
      name: 'totalBuybackAmountProvided',
    },
    {
      address: getBuybackAddress(),
      name: 'currentEpoch',
    },
    {
      address: getBuybackAddress(),
      name: 'nextEpochTimestamp',
    },
    {
      address: getBuybackAddress(),
      name: 'totalApplyAmountThisEpoch',
    },
    {
      address: getBuybackAddress(),
      name: 'applyFeeAmount',
    },
  ]

  const [
    buybackPrice,
    epochAmount,
    totalAmountToProvide,
    totalAmountProvided,
    currentEpoch,
    timestampNextEpoch,
    totalApplyAmountThisEpoch,
    applyFeeAmount,
  ] = await multicall(buybackABI, calls)
  return {
    buybackPrice: new BigNumber(buybackPrice),
    epochAmount: new BigNumber(epochAmount),
    totalAmountToProvide: new BigNumber(totalAmountToProvide),
    totalAmountProvided: new BigNumber(totalAmountProvided),
    currentEpoch: new BigNumber(currentEpoch),
    timestampNextEpoch: new BigNumber(timestampNextEpoch),
    totalApplyAmountThisEpoch: new BigNumber(totalApplyAmountThisEpoch),
    applyFeeAmount: new BigNumber(applyFeeAmount),
  }
}

export default fetchPool
