import BigNumber from 'bignumber.js'
import { useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import useRefresh from 'hooks/useRefresh'
import {
  fetchFarmsPublicDataAsync,
  fetchPoolsPublicDataAsync,
  fetchPoolsUserDataAsync,
  fetchVaultsPublicDataAsync,
  fetchVaultUserDataAsync,
  fetchBuybackPoolDataAsync,
  fetchBuybackUserDataAsync,
  fetchReferralDataAsync,
  fetchSatBalanceDataAsync,
} from './actions'
import { State, Farm, Pool, Vault, Buyback, SatBalance } from './types'
import { QuoteToken } from '../config/constants/types'

const ZERO = new BigNumber(0)
const CHAIN_ID = process.env.REACT_APP_CHAIN_ID

// Sat balance

export const useFetchSatBalanceData = (account) => {
  const dispatch = useDispatch()
  const { slowRefresh } = useRefresh()
  useEffect(() => {
    if (account) {
      dispatch(fetchSatBalanceDataAsync(account))
    }
  }, [dispatch, slowRefresh, account])
}

export const useSatBalanceUser = () => {
  const satBalanceUser = useSelector((state: State) => state.satBalance.data.satBalance)
  return satBalanceUser
}

// Farms, Vaults - User

export const useFetchPublicData = () => {
  const dispatch = useDispatch()
  const { slowRefresh } = useRefresh()
  useEffect(() => {
    dispatch(fetchFarmsPublicDataAsync())
    // dispatch(fetchPoolsPublicDataAsync())
  }, [dispatch, slowRefresh])
}

export const useFetchVaultPublicData = () => {
  const dispatch = useDispatch()
  const { slowRefresh } = useRefresh()
  useEffect(() => {
    dispatch(fetchVaultsPublicDataAsync())
  }, [dispatch, slowRefresh])
}

export const useFetchVaultUserData = (account) => {
  const dispatch = useDispatch()
  const { slowRefresh } = useRefresh()
  useEffect(() => {
    if (account) {
      dispatch(fetchVaultUserDataAsync(account))
    }
  }, [account, dispatch, slowRefresh])
}

// Referral

export const useFetchReferralUser = (account) => {
  const dispatch = useDispatch()
  const { slowRefresh } = useRefresh()
  useEffect(() => {
    if (account) {
      dispatch(fetchReferralDataAsync(account))
    }
  }, [account, dispatch, slowRefresh])
}

export const useReferralUser = () => {
  const referralUser = useSelector((state: State) => state.referral.data.referral)
  return referralUser
}

// Buyback

export const useFetchBuybackPool = () => {
  const dispatch = useDispatch()
  const { slowRefresh } = useRefresh()
  useEffect(() => {
    dispatch(fetchBuybackPoolDataAsync())
  }, [dispatch, slowRefresh])
}

export const useFetchBuybackUser = (account) => {
  const dispatch = useDispatch()
  const { slowRefresh } = useRefresh()
  useEffect(() => {
    if (account) {
      dispatch(fetchBuybackUserDataAsync(account))
    }
  }, [account, dispatch, slowRefresh])
}

export const useBuybackPool = () => {
  const buybackPool = useSelector((state: State) => state.buyback.data.pool)
  return buybackPool
}

export const useBuybackUser = () => {
  const buybackUser = useSelector((state: State) => state.buyback.data.user)
  return buybackUser
}

// Farms

export const useFarms = (): Farm[] => {
  const farms = useSelector((state: State) => state.farms.data)
  return farms
}

export const useFarmFromPid = (pid): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.pid === pid))
  return farm
}

export const useFarmFromSymbol = (lpSymbol: string): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.lpSymbol === lpSymbol))
  return farm
}

export const useFarmUser = (pid) => {
  const farm = useFarmFromPid(pid)

  return {
    allowance: farm.userData ? new BigNumber(farm.userData.allowance) : new BigNumber(0),
    tokenBalance: farm.userData ? new BigNumber(farm.userData.tokenBalance) : new BigNumber(0),
    stakedBalance: farm.userData ? new BigNumber(farm.userData.stakedBalance) : new BigNumber(0),
    earnings: farm.userData ? new BigNumber(farm.userData.earnings) : new BigNumber(0),
  }
}

// Pools

export const usePools = (account): Pool[] => {
  const { fastRefresh } = useRefresh()
  const dispatch = useDispatch()
  useEffect(() => {
    if (account) {
      dispatch(fetchPoolsUserDataAsync(account))
    }
  }, [account, dispatch, fastRefresh])

  const pools = useSelector((state: State) => state.pools.data)
  return pools
}

export const usePoolFromPid = (sousId): Pool => {
  const pool = useSelector((state: State) => state.pools.data.find((p) => p.sousId === sousId))
  return pool
}

// Vaults

export const useVaults = (): Vault[] => {
  const vaults = useSelector((state: State) => state.vaults.data)
  return vaults
}

export const useVaultFromAddress = (addr): Vault => {
  const vault = useSelector((state: State) =>
    state.vaults.data.find((f) => f.vaultAddresses[CHAIN_ID].toLowerCase() === addr.toLowerCase()),
  )
  return vault
}

export const useVaultFromEarnTokenAddress = (addr): Vault => {
  const vault = useSelector((state: State) =>
    state.vaults.data.find((f) => f.earnTokenAddresses[CHAIN_ID].toLowerCase() === addr.toLowerCase()),
  )
  return vault
}

export const useVaultUser = (addr) => {
  const vault = useVaultFromAddress(addr)

  return {
    allowance: vault.userData ? new BigNumber(vault.userData.allowance) : new BigNumber(0),
    tokenBalance: vault.userData ? new BigNumber(vault.userData.tokenBalance) : new BigNumber(0),
    stakedBalance: vault.userData ? new BigNumber(vault.userData.stakedBalance) : new BigNumber(0),
  }
}

// Prices

export const usePriceBnbBusd = (): BigNumber => {
  const pid = 0 // BUSD-BNB LP
  const farm = useFarmFromPid(pid)
  return farm.tokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : ZERO
}

export const usePriceCakeBusd = (): BigNumber => {
  // const pid = 1 // CAKE-BNB LP
  // const bnbPriceUSD = usePriceBnbBusd()
  // const farm = useFarmFromPid(pid)
  // return farm.tokenPriceVsQuote ? bnbPriceUSD.times(farm.tokenPriceVsQuote) : ZERO
  const pid = 10 // SAT-BUSD LP
  const farm = useFarmFromPid(pid)
  return farm.tokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : ZERO
}

export const usePriceEarn = (earnTokenAddress: string): BigNumber => {
  const vault = useVaultFromEarnTokenAddress(earnTokenAddress)
  return vault.tokenPriceVsQuote ? new BigNumber(vault.tokenPriceVsQuote) : ZERO
}

export const useTotalValue = (): BigNumber => {
  const farms = useFarms()
  const bnbPrice = usePriceBnbBusd()
  const cakePrice = usePriceCakeBusd()
  let value = new BigNumber(0)
  for (let i = 0; i < farms.length; i++) {
    const farm = farms[i]
    if (farm.lpTotalInQuoteToken) {
      let val
      if (farm.quoteTokenSymbol === QuoteToken.BNB) {
        val = bnbPrice.times(farm.lpTotalInQuoteToken)
      } else if (farm.quoteTokenSymbol === QuoteToken.SAT) {
        val = cakePrice.times(farm.lpTotalInQuoteToken)
      } else {
        val = farm.lpTotalInQuoteToken
      }
      value = value.plus(val)
    }
  }

  // add vaults
  const vaults = useVaults()
  for (let i = 0; i < vaults.length; i++) {
    const vault = vaults[i]
    if (vault.lpVaultInQuoteToken) {
      let val
      if (vault.quoteTokenSymbol === QuoteToken.BNB) {
        val = bnbPrice.times(vault.lpVaultInQuoteToken)
      } else {
        val = vault.lpVaultInQuoteToken
      }
      value = value.plus(val)
    }
  }
  return value
}
