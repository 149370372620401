import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import farmsReducer from './farms'
import poolsReducer from './pools'
import vaultsReducer from './vaults'
import buybackReducer from './buyback'
import referralReducer from './referral'
import satBalanceReducer from './migrateSat'

export default configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    farms: farmsReducer,
    pools: poolsReducer,
    vaults: vaultsReducer,
    buyback: buybackReducer,
    referral: referralReducer,
    satBalance: satBalanceReducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
})
