/* eslint-disable no-param-reassign */
import BigNumber from 'bignumber.js'
import { createSlice } from '@reduxjs/toolkit'
import fetchReferral from './fetchReferral'
import { ReferralState, Referral } from '../types'

const initialState: ReferralState = {
  data: {
    referral: {
      referralCount: new BigNumber(0),
    },
  },
}

export const referralSlice = createSlice({
  name: 'Referral',
  initialState,
  reducers: {
    setReferralData: (state, action) => {
      const referralData = action.payload
      state.data.referral = referralData
    },
  },
})

// Actions
export const { setReferralData } = referralSlice.actions

// Thunks
export const fetchReferralDataAsync = (account: string) => async (dispatch) => {
  const referral = await fetchReferral(account)
  dispatch(setReferralData(referral))
}

export default referralSlice.reducer
