import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  //  {
  //    label: 'Buyback',
  //    icon: 'BuybackIcon',
  //    href: '/buyback',
  //  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Exchange',
        //        href: 'https://exchange.satis.finance/',
        href: 'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x8fda94079913CB921D065Ed9c004Afb43e1f900e',
        target: '_blank',
      },
      {
        label: 'Liquidity',
        //        href: 'https://exchange.satis.finance/#/pool',
        href: 'https://exchange.pancakeswap.finance/#/pool',
        target: '_blank',
      },
      {
        label: 'SAT ➝ xSAT',
        href: '/migrationSat',
      },
    ],
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: 'Pools',
    icon: 'PoolIcon',
    href: '/pools',
  },
  {
    label: 'Vaults',
    icon: 'VaultIcon',
    href: '/vaults',
  },
  //  {
  //    label: 'LEGACY',
  //    icon: 'FarmIcon',
  //    href: '/farmsLegacy',
  //  },
  {
    label: 'Lottery',
    icon: 'TicketIcon',
    href: '/lottery',
  },
  {
    label: 'Referrals',
    icon: 'GroupsIcon',
    href: '/referrals',
  },
  {
    label: 'Legacy',
    icon: 'HomeIcon',
    href: 'https://legacy.satis.finance/',
    target: '_blank',
  },
  // {
  //   label: 'NFT',
  //   icon: 'NftIcon',
  //   href: '/nft',
  // },
  {
    label: 'Info',
    icon: 'InfoIcon',
    items: [
      {
        label: 'PancakeSwap',
        href: 'https://pancakeswap.info/token/0x8fda94079913CB921D065Ed9c004Afb43e1f900e',
      },
      {
        label: 'CoinGecko',
        href: 'https://www.coingecko.com/en/coins/satisfinance',
      },
      {
        label: 'CoinMarketCap',
        href: 'https://coinmarketcap.com/currencies/satisfinance-token/',
      },
      {
        label: 'Poocoin',
        href: 'https://poocoin.app/tokens/0x8fda94079913CB921D065Ed9c004Afb43e1f900e',
      },
    ],
  },
  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      {
        label: 'Docs',
        href: 'https://docs.satis.finance/',
      },
      {
        label: 'Blog',
        href: 'https://satisfinance.medium.com/',
      },
    ],
  },
  {
    label: 'Audit by TechRate',
    icon: 'AuditIcon',
    href: 'https://www.satis.finance/files/SatisFi.pdf',
  },
  {
    label: 'Audit by BSC Checker',
    icon: 'AuditIcon',
    href: 'https://twitter.com/BscChecker/status/1388131060865769475?s=20',
  },
]

export default config
